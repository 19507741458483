import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Link from 'gatsby-link'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import ColumnAlternatingButton from '../components/columnAlternatingButton'
import ColumnAlternatingYouTube from '../components/columnAlternatingYouTube'
import PerformanceFocus from '../components/performanceFocus'
import Card from '../components/card'
import CardIconsLink from '../components/cardIconsLink.js'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact'
import TechPartnerPlatforms from '../components/techPartnerPlatforms'
import Cta from '../components/cta'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import Showdown from 'showdown'

function parseLink(value) {
  value = value && value.lastIndexOf("../") > 0 ? value.substring(value.lastIndexOf("../")+2) : value
  return value
}

export default ({ data, location }) => {
  const post = data.markdownRemark
  const converter = new Showdown.Converter()

  return (
    <Layout>
      <div id="sub-page">
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname}
          image={
            data.site.siteMetadata.siteUrl +
            post.frontmatter.image.childImageSharp.fluid.src
          }
        />

        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.fluid}
          foreimage={null}
          imageAltText={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}           
          type="root"
        />
      </div>

      <section className="bg-white">
        <MDBContainer>
          <MDBRow>
            <MDBCol lg="12">
              <div
                className="mt-3 richtext divlink tp-overview"
                dangerouslySetInnerHTML={{ __html: post.html }}
              />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>


      {post.frontmatter.podcasts && 
        <section className="bg-white-grey-gradient">
          <MDBContainer>
            <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left">
              {post.frontmatter.podcasts.title}
            </h2>

            {post.frontmatter.podcasts.subtitle &&
              <h3 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left">
                {post.frontmatter.podcasts.title}
              </h3>
            }

            {post.frontmatter.podcasts.description &&
              <div
                className="mt-3 richtext divlink tp-overview"
                dangerouslySetInnerHTML={{ __html: converter.makeHtml(post.frontmatter.podcasts.description) }}
              />
            }

            {post.frontmatter.podcasts.podcast && (
              <MDBRow className="pt-5">
                {post.frontmatter.podcasts.podcast.map((podcast, index) => (
                  <Card
                    key={index}
                    collg="4"
                    colmd="6"
                    height="7.5rem"
                    title={podcast.title}
                    subtitle={podcast.subtitle}
                    description={podcast.linktext}
                    imageUrl={podcast.image.childImageSharp.fluid}
                    imageAltText={podcast.alttext}
                    placement="card"
                    link={ podcast.link ? parseLink(podcast.link) : null }
                    descriptionClass="text-card-small"
                  />
                )
                )}
              </MDBRow>
            )}
          </MDBContainer>
        </section>
      }


      {post.frontmatter.video && (
        <section className={post.frontmatter.podcasts ? "bg-white" : "bg-white-grey-gradient"}>
          <MDBAnimation reveal type="fadeIn" duration="1587ms">
            <MDBContainer>
              <div className="about-overview">
                {post.frontmatter.video.map((videos, index) => {
                  return (
                    <ColumnAlternatingYouTube
                      key={index}
                      title={videos.title}
                      subtitle={videos.subtitle}
                      openingpara={videos.openingpara}
                      description={videos.description}
                      vidUrl={videos.videourl}
                      placement={videos.placement}
                      titleClass="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium"
                      link={videos.link}
                      linktext={videos.linktext}
                      ctalink={videos.ctalink}
                      ctatext={videos.ctatext}
                      icon="right"
                    />
                  )
                })}
              </div>
            </MDBContainer>
          </MDBAnimation>
        </section>
      )}


      {post.frontmatter.award && (
        <section className="bg-white-grey-gradient">
          <MDBContainer>
            <ColumnAlternatingButton
              textCol={'8'}
              imageCol={'4'}
              title={post.frontmatter.award.title}
              description={post.frontmatter.award.description}
              imageUrl={post.frontmatter.award.image.childImageSharp.fluid}
              imageAltText={post.frontmatter.award.alttext}
              placement={post.frontmatter.award.placement}
              titleClass="font-alt font-w-400 letter-spacing-1 mb-4 title-xs-medium title-medium"
              link={post.frontmatter.award.link}
              linktext={post.frontmatter.award.linktext}
            />
          </MDBContainer>
        </section>
      )}


      {post.frontmatter.performance && (
       <section className="bg-blue-gradient">
        <MDBContainer>
          <PerformanceFocus performance={post.frontmatter.performance} />
        </MDBContainer>
       </section>
      )}


      {post.frontmatter.section && post.frontmatter.section[0] && (
        <section className="bg-white-grey-gradient">
          <MDBContainer>
            <MDBRow>
              <MDBCol lg="12">
                <h3 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-large title-large pb-4">
                  {post.frontmatter.section[0].title}
                </h3>
              </MDBCol>
              <MDBCol lg="12">
                <div 
                  className="pb-4 text-medium divlink"
                  dangerouslySetInnerHTML={{ __html: converter.makeHtml(post.frontmatter.section[0].description) }}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              {post.frontmatter.section[0].feature.map((platforms, index) => {
                return (
                  <TechPartnerPlatforms
                    key={index}
                    title={platforms.title}
                    subtitle={platforms.subtitle}
                    description={platforms.description}
                    image={platforms.image.childImageSharp.fluid}
                    linktext={platforms.linktext}
                    link={parseLink(platforms.link)}
                    datasheetlinktext={platforms.datasheetlinktext}
                    datasheetlink={ platforms.document && platforms.document.publicURL }
                    alttext={platforms.alttext}
                  />
                )
              })}
            </MDBRow>
            {post.frontmatter.section[0].link && (
              <MDBRow>
                <MDBCol lg="12">
                  <div>
                    <Link
                      // to={post.frontmatter.section[0].link}
                      // to={post.frontmatter.section[0].link.substring(post.frontmatter.section[0].link.lastIndexOf("../")+2)}
                      to="/platforms/"
                      className="nav-link btn btn-mdb-color btn-sm-block mr-0 mt-xl-3 btn-sm-block"
                      style={{ backgroundColor: 'rgb(41,83,149)', color: '#fff', }}
                    >
                      <FontAwesomeIcon icon={faChevronRight} className="mr-2" />{' '}
                      {post.frontmatter.section[0].linktext}
                    </Link>
                  </div>
                </MDBCol>
              </MDBRow>
            )}
          </MDBContainer>
        </section>
      )}


      {post.frontmatter.whywork && (
        <section className="bg-white">
          <MDBAnimation type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <h3 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-large title-large pb-3">
                {post.frontmatter.whywork.title}
              </h3>
              <div
                className="mb-5 font-w-400 text-medium"
                dangerouslySetInnerHTML={{ __html: converter.makeHtml(post.frontmatter.whywork.subtitle) }}
              />
                <ColumnAlternatingButton
                  textCol={'5'}
                  imageCol={'7'}
                  title={post.frontmatter.whywork.details.title}
                  subtitle={post.frontmatter.whywork.details.subtitle}
                  description={post.frontmatter.whywork.details.description}
                  imageUrl={ post.frontmatter.whywork.details.image.childImageSharp.fluid }
                  imageAltText={post.frontmatter.whywork.details.alttext}
                  imageClass="rounded drop-shadow"
                  placement={post.frontmatter.whywork.details.placement}
                  titleClass="font-alt font-w-400 letter-spacing-1 title-xs-medium title-medium"
                  link={post.frontmatter.whywork.details.link}
                  linktext={post.frontmatter.whywork.details.linktext}
                />
            </MDBContainer>
          </MDBAnimation>
        </section>
      )}


      {post.frontmatter.section && post.frontmatter.section[1] && (
        <section className="bg-white-grey-gradient">
          <MDBContainer>
            <MDBRow>
              <MDBCol lg="12">
                <h3 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-medium pb-3">
                  {post.frontmatter.section[1].title}
                </h3>
              </MDBCol>
              <MDBCol lg="12">
                <div 
                  className="pb-4 text-medium divlink"
                  dangerouslySetInnerHTML={{ __html: converter.makeHtml(post.frontmatter.section[1].description) }}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              {post.frontmatter.section[1].feature.map((servers, index) => {
                return (
                  <TechPartnerPlatforms
                    key={index}
                    title={servers.title}
                    subtitle={servers.subtitle}
                    image={servers.image.childImageSharp.fluid}
                    linktext={servers.linktext}
                    link={parseLink(servers.link)}
                    datasheetlinktext={servers.datasheetlinktext}
                    datasheetlink={ servers.document && servers.document.publicURL }
                    alttext={servers.alttext}
                  />
                )
              })}
            </MDBRow>
            {post.frontmatter.section[1].link && (
              <MDBRow>
                <MDBCol lg="12">
                  <div>
                    <Link
                      // to={post.frontmatter.section[2].link}
                      // to={post.frontmatter.section[2].link.substring(post.frontmatter.section[2].link.lastIndexOf("../")+2)}
                      to="/platforms/"
                      className="nav-link btn btn-mdb-color mr-0 mt-xl-3 btn-sm-block"
                      style={{ backgroundColor: 'rgb(41,83,149)', color: '#fff', }}
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faChevronRight} className="mr-2" />{' '}
                      {post.frontmatter.section[1].linktext}
                    </Link>
                  </div>
                </MDBCol>
              </MDBRow>
            )}
          </MDBContainer>
        </section>
      )}



      {post.frontmatter.section && post.frontmatter.section[2] && (
        <section className="bg-white-grey-gradient">
          <MDBContainer>
            <MDBRow>
              <MDBCol lg="12">
                <h3 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-medium pb-3">
                  {post.frontmatter.section[2].title}
                </h3>
              </MDBCol>
              <MDBCol lg="12">
                <div 
                  className="pb-4 text-medium divlink"
                  dangerouslySetInnerHTML={{ __html: converter.makeHtml(post.frontmatter.section[2].description) }}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              {post.frontmatter.section[2].feature.map((servers, index) => {
                return (
                  <TechPartnerPlatforms
                    key={index}
                    title={servers.title}
                    subtitle={servers.subtitle}
                    image={servers.image.childImageSharp.fluid}
                    linktext={servers.linktext}
                    link={parseLink(servers.link)}
                    datasheetlinktext={servers.datasheetlinktext}
                    datasheetlink={ servers.document && servers.document.publicURL }
                    alttext={servers.alttext}
                  />
                )
              })}
            </MDBRow>
            {post.frontmatter.section[2].link && (
              <MDBRow>
                <MDBCol lg="12">
                  <div>
                    <Link
                      // to={post.frontmatter.section[2].link}
                      // to={post.frontmatter.section[2].link.substring(post.frontmatter.section[2].link.lastIndexOf("../")+2)}
                      to="/platforms/"
                      className="nav-link btn btn-mdb-color mr-0 mt-xl-3 btn-sm-block"
                      style={{ backgroundColor: 'rgb(41,83,149)', color: '#fff', }}
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faChevronRight} className="mr-2" />{' '}
                      {post.frontmatter.section[2].linktext}
                    </Link>
                  </div>
                </MDBCol>
              </MDBRow>
            )}
          </MDBContainer>
        </section>
      )}


      {post.frontmatter.section && post.frontmatter.section[3] && (
        <section className="bg-white-grey-gradient">
          <MDBContainer>
            <MDBRow>
              <MDBCol lg="12">
                <h3 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-medium pb-3">
                  {post.frontmatter.section[3].title}
                </h3>
              </MDBCol>
              <MDBCol lg="12">
                <div 
                  className="pb-4 text-medium divlink"
                  dangerouslySetInnerHTML={{ __html: converter.makeHtml(post.frontmatter.section[3].description) }}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              {post.frontmatter.section[3].feature.map((servers, index) => {
                return (
                  <TechPartnerPlatforms
                    key={index}
                    title={servers.title}
                    subtitle={servers.subtitle}
                    image={servers.image.childImageSharp.fluid}
                    linktext={servers.linktext}
                    link={parseLink(servers.link)}
                    datasheetlinktext={servers.datasheetlinktext}
                    datasheetlink={ servers.document && servers.document.publicURL }
                    alttext={servers.alttext}
                  />
                )
              })}
            </MDBRow>
            {post.frontmatter.section[3].link && (
              <MDBRow>
                <MDBCol lg="12">
                  <div>
                    <Link
                      // to={post.frontmatter.section[2].link}
                      // to={post.frontmatter.section[2].link.substring(post.frontmatter.section[2].link.lastIndexOf("../")+2)}
                      to="/platforms/"
                      className="nav-link btn btn-mdb-color mr-0 mt-xl-3 btn-sm-block"
                      style={{ backgroundColor: 'rgb(41,83,149)', color: '#fff', }}
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faChevronRight} className="mr-2" />{' '}
                      {post.frontmatter.section[3].linktext}
                    </Link>
                  </div>
                </MDBCol>
              </MDBRow>
            )}
          </MDBContainer>
        </section>
      )}


      {post.frontmatter.asset && (
        <>
          {post.frontmatter.asset.map((assets, index) => {
            return (
              <section
                className={
                  !post.frontmatter.section || !post.frontmatter.section[0]
                    ? 'bg-white-grey-gradient'
                    : index % 2
                    ? 'bg-white-grey-gradient'
                    : 'bg-white'
                }
                key={index}
              >
                <MDBContainer>
                  <ColumnAlternatingButton
                    textCol={'8'}
                    imageCol={'4'}
                    title={assets.title}
                    subtitle={assets.subtitle}
                    description={assets.description}
                    imageUrl={assets.image.childImageSharp.fluid}
                    imageAltText={assets.alttext}
                    placement={assets.placement}
                    titleClass="font-alt font-w-400 letter-spacing-1 mb-4 title-xs-medium title-medium"
                    link={parseLink(assets.link)}
                    linktext={assets.linktext}
                  />
                </MDBContainer>
              </section>
            )
          })}
        </>
      )}


      {post.frontmatter.team && (
        <section className="bg-white-grey-gradient">
          <MDBAnimation reveal type="fadeIn" duration="1587ms">
            <MDBContainer>
              <h3 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-medium pb-5">
                {post.frontmatter.team.title}
              </h3>
              {post.frontmatter.team.featurecard && (
                <MDBRow>
                  <>
                    {post.frontmatter.team.featurecard.map((cards, index) => {
                      return (
                        <CardIconsLink
                          key={index}
                          title={cards.title}
                          link={cards.link}
                          imageUrl={cards.image.childImageSharp.fixed}
                          imageAltText={cards.alttext}
                        />
                      )
                    })}
                  </>
                </MDBRow>
              )}
            </MDBContainer>
          </MDBAnimation>
        </section>
      )}


      {post.frontmatter.model && (
        <section className="bg-white-grey-gradient">
          <MDBContainer>
            <ColumnAlternatingButton
              textCol={'5'}
              imageCol={'7'}
              title={post.frontmatter.model.title}
              description={post.frontmatter.model.description}
              imageUrl={post.frontmatter.model.image.childImageSharp.fluid}
              imageAltText={post.frontmatter.model.alttext}
              placement={post.frontmatter.model.placement}
              titleClass="font-alt font-w-400 letter-spacing-1 mb-4 title-xs-medium title-medium"
              link={post.frontmatter.model.link}
              linktext={post.frontmatter.model.linktext}
              icon="right"
            />
          </MDBContainer>
        </section>
      )}



      {post.frontmatter.helpfulresources && (
        <section className="bg-gray-light">
          <MDBAnimation reveal type="fadeIn" duration="1587ms">
            <MDBContainer>
              <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left">
                {post.frontmatter.helpfulresources.title}
              </h2>
              {post.frontmatter.helpfulresources.helpfulres && (
                <MDBRow className="pt-5">
                  <>
                    {post.frontmatter.helpfulresources.helpfulres.map(
                      (helpfulres, index) => {
                        return (
                          <Card
                            key={index}
                            collg="4"
                            colmd="6"
                            height="7.5rem"
                            title={helpfulres.title}
                            subtitle={helpfulres.subtitle}
                            description={helpfulres.description}
                            imageUrl={helpfulres.image.childImageSharp.fluid}
                            imageAltText={helpfulres.alttext}
                            placement={helpfulres.placement}
                            link={ helpfulres.link ? parseLink(helpfulres.link) : helpfulres.document.publicURL }
                            descriptionClass="text-card-small"
                          />
                        )
                      }
                    )}
                  </>
                </MDBRow>
              )}
            </MDBContainer>
          </MDBAnimation>
        </section>
      )}


      <section className="bg-blue-green-gradient">
        <MDBContainer>
          <Cta
            ctatitle={post.frontmatter.cta.title}
            ctadescription={post.frontmatter.cta.description}
            ctalink={post.frontmatter.cta.link}
            ctalinktext={post.frontmatter.cta.linktext}
          />
        </MDBContainer>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }       
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "technology-partners" } }
    ) {
      frontmatter {
        meta {
          title
          description 
        }
        title
        subtitle
        alttext
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alttext
        ctatext
        ctaslug 
        logo {
          childImageSharp {
            fluid(maxWidth: 300, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        logoalttext        
        performance {
          title
          subtitle
          items {
            title
            subtitle
            link
            linktext
          }
        }
        section {
          title
          description
          link
          linktext
          platformlink
          platformlinktext
          document {
            publicURL
          }
          feature {
            title
            subtitle
            alttext
            linktext
            link
            datasheetlinktext
            datasheetlink
            document {
              publicURL
            }
            image {
              childImageSharp {
                fluid(maxWidth: 300, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        whywork {
          title
          subtitle
          details {
            title
            subtitle
            description
            placement
            linktext
            link
            alttext
            image {
              childImageSharp {
                fluid(maxWidth: 900, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }        
        award {
          title
          description
          placement
          linktext
          link
          alttext
          image {
            childImageSharp {
              fluid(maxWidth: 394, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        asset {
          title
          subtitle
          description
          placement
          linktext
          link
          alttext
          image {
            childImageSharp {
              fluid(maxWidth: 540, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        cta {
          title
          description
          linktext
          link
        }
        video {
          title
          openingpara
          description
          placement
          videourl
          link
          linktext
          ctalink
          ctatext
        }
        model {
          title
          description
          placement
          linktext
          link
          alttext
          image {
            childImageSharp {
              fluid(maxWidth: 500, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        team {
          title
          featurecard {
            title
            link
            alttext
            image {
              childImageSharp {
                fixed(width: 70, height: 70, quality: 90) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
        podcasts {
          title
          description 
          podcast {
            title
            link
            linktext 
            alttext
            image {
              childImageSharp {
                fluid(maxWidth: 600, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        helpfulresources {
          title
          helpfulres {
            title
            subtitle
            description
            placement
            link
            linktext
            alttext
            image {
              childImageSharp {
                fluid(maxWidth: 600, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            document {
              publicURL
            }           
          }
        }
      }
      html
    }
  }
`